<div class="main-content">
  <div class="app-side-menu-wrapper">
    <app-side-menu></app-side-menu>
  </div>
  <div class="right-content">
    <div class="app-shop-filter" [ngClass]="{ 'open': isMenuOpen }">
      <app-stats-filter *ngIf="isMenuOpen" [previousSearchModel]="previousSearchData" [statsViewModel]="statsViewModel"
        (searchStatsEvent)="SearchStats($event)" class="position-absolute top-0 right-0"></app-stats-filter>
      <!-- (searchStats)="SearchStats($event)" -->
    </div>
    <app-top-menu></app-top-menu>
    <div class="content mx-2">
      <div *ngIf="isLoading" class="loading-container" role="alert" aria-live="assertive">
        <app-loading frameborder="0"></app-loading>
      </div>
      <div>
        <!-- Stats Buttons -->
        <div class="d-flex flex-row" style="justify-content: space-between;">
          <div class="d-flex flex-row" style="gap:16px">
            <!-- Filters -->
            <button class="filter-btn" [disabled]="!isViewModelLoaded" aria-label="Abrir filtros" (click)="toggleMenu()">
              <span>Filtros</span>
              <img src="../../../assets/Icons/filterIcon.png" alt="Ícone de filtros">
            </button>
            <!-- Download -->
            <button type="button" (click)="DownloadStatsData()" [disabled]="isLoading" aria-label="Exportar dados estatísticos" class="export-btn">
              <span>Exportar dados</span>
              <img src="../../../assets/Icons/ExportIcon.png" alt="Ícone de exportar dados estatísticos">
            </button></div>
        </div>

        <!-- Stats Content-->
        <div id="statistics-content" [hidden]="isLoading">

          <!-- Annual Consumption -->
          <div [ngClass]="{ 'statistics-card-100' : isAnnualConsumption }"
            [ngClass]="{ 'statistics-card-uneven' : isTop25}" class="statistics-card">
            <label>Consumo Anual</label>
            <div class="stats-card-content" (click)="ShowStatsDetails('AnnualGraph')">
              <canvas #consumptionChart id="consumptionChart"></canvas>
            </div>
          </div>

          <!-- Top 25 -->
          <div [ngClass]="{ 'statistics-card-100' : isTop25 }" class="statistics-card" *ngIf="this.loggedUser.role !== 'Laboratorio'">
            <label>Top 25</label>
            <div class="stats-card-content" (click)="ShowStatsDetails('Top25')">
              <canvas #top25chart id="top25chart"></canvas>
            </div>
          </div>

          <!-- Product Data -->
          <div [ngClass]="{ 'statistics-card-100' : isProductTable }"
            [ngClass]="{ 'statistics-card-uneven' : isLaboratoryTable}" class="statistics-card">
            <label>Produto</label>
            <div class="stats-card-content" (click)="ShowStatsDetails('ProductTable')">
              <div class="stats-grid">
                <div class="stats-grid-cell">
                  <div class="stats-grid-cell-header">Designação</div>
                  <div class="stats-grid-cell-value">
                    {{statsSearchResults?.productResults?.[0]?.designation || 'Nenhum selecionado'}}</div>
                </div>
                <div class="stats-grid-cell">
                  <div class="stats-grid-cell-header">Qtd do Produto Faturada</div>
                  <div class="stats-grid-cell-value">{{statsSearchResults?.productResults?.[0]?.quantityCashed || '0'}}
                  </div>
                </div>
                <div class="stats-grid-cell">
                  <div class="stats-grid-cell-header">Qtd do Produto Bónus</div>
                  <div class="stats-grid-cell-value">{{statsSearchResults?.productResults?.[0]?.quantityBonus || '0'}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Laboratory Data -->
          <div [ngClass]="{ 'statistics-card-100' : isLaboratoryTable }" class="statistics-card mobiledisplay">
            <label>Laboratório</label>
            <div class="stats-card-content" (click)="ShowStatsDetails('LaboratoryTable')">
              <div [hidden]="isLaboratoryTable">
                <div class="stats-grid-cell-header">
                  {{activeLaboratory !== '' ? this.activeLaboratoryName : 'Nenhum selecionado'  }}</div>
                <div class="stats-grid-cell-value">
                  {{statsSearchResults?.laboratoriesResults?.length + ' resultados' || '0 resultados'}}</div>
              </div>
              <div [hidden]="!isLaboratoryTable">
                <div class="scrollable-table">
                  <table class="table" id="laboratoryTable" aria-labelledby="Tabela de estatísticas de laboratório">
                    <thead>
                      <tr>
                        <th>Designação</th>
                        <th>Qtd do Produto Faturada</th>
                        <th>Qtd do Produto Bónus</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let lab of statsSearchResults?.laboratoriesResults">
                        <td>{{ lab.designation }}</td>
                        <td>{{ lab.quantityCashed }}</td>
                        <td>{{ lab.quantityBonus }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- Substance Data -->
          <div [ngClass]="{ 'statistics-card-100' : isSubstanceTable }" class="statistics-card mobiledisplay" *ngIf="this.loggedUser.role !== 'Laboratorio'">
            <label>Substância</label>
            <div class="stats-card-content" (click)="ShowStatsDetails('SubstanceTable')">
              <div [hidden]="!isSubstanceTable">
                <div class="scrollable-table">

                  <table class="table" id="substanceTable" aria-labelledby="Tabela de estatísticas de substâncias">
                    <thead>
                      <tr>
                        <th>Designação</th>
                        <th>Qtd do Produto Faturada</th>
                        <th>Qtd do Produto Bónus</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sub of statsSearchResults?.substancesResults">
                        <td>{{ sub.designation }}</td>
                        <td>{{ sub.quantityCashed}}</td>
                        <td>{{ sub.quantityBonus}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div [hidden]="isSubstanceTable">
                <div class="stats-grid-cell-header">
                  {{activeSubstance !== '' ? this.activeSubstanceName : 'Nenhum selecionado'  }}</div>
                <div class="stats-grid-cell-value">
                  {{statsSearchResults?.substancesResults?.length + ' resultados' || '0 resultados'}}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="app-shop-filter" [ngClass]="{ 'open': isMenuOpen }">
      <app-stats-filter (isOpen)="closeMenu()" (searchStatsEvent)="SearchStats($event)"
        [statsViewModel]="statsViewModel" *ngIf="isMenuOpen" class="position-absolute top-0 right-0 w-100 h-100">
      </app-stats-filter>
    </div>
    <div class="app-backdrop" [ngClass]="{ 'open': isMenuOpen || isModalOpen }" (click)="closeMenu()"></div>
    <app-footer></app-footer>
  </div>
</div>
