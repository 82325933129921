import { DialogProductCampaignComponent } from './dialog-product-campaign/dialog-product-campaign.component';
import { LoginComponent } from './../../login/login.component';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import {
  FavoriteRequest, QuantityCampaignDetails,
  ShopItemModel,
} from 'src/app/api/models/shop-item.model';
import { ShopService } from 'src/app/api/services/shop.service';
import { PerfilService } from 'src/app/api/services/perfil.service';
import { ProfileDataRequest, UserInfo } from 'src/app/api/models/perfil';
import { MatDialog } from '@angular/material/dialog';
import { ProductModalComponent } from '../../Main/shop/product-modal/product-modal.component';
import { CustomIconsService } from 'src/app/api/services/custom-icons.service';
import { FavoriteModalComponent } from '../../Main/favorites/favorite-modal/favorite-modal.component';
import { SnackBarService } from 'src/app/api/services/snack-bar.service';
import { CheckoutService } from 'src/app/api/services/checkout.service';
import { CartService } from 'src/app/api/services/cart.service';
import { ChangeDetectorRef } from '@angular/core';

import { CartProductModel } from 'src/app/api/models/checkout';
import { environment } from 'src/environments/environment';
import { Subscribable, Subscription } from 'rxjs';
import { TbCliente } from 'src/app/api/models/tb-cliente';
import { User } from 'src/app/api/models/getUsers';
import { UserDto } from 'src/app/api/models';
import { AuthService } from 'src/app/api/services/auth.service';
import { DialogData, ProductBonusModalComponent } from '../product-bonus-modal/product-bonus-modal.component';
import { PointsService } from 'src/app/api/services/points.service';


@Component({
  selector: 'app-product-shop',
  templateUrl: './product-shop.component.html',
  styleUrls: ['./product-shop.component.css'],
  animations: [
    trigger('moveToTopRight', [
      state(
        'start',
        style({
          opacity: 0,
          transform: 'scale(3)',
        })
      ),
      state(
        'end',
        style({
          opacity: 1,
          transform: 'scale(1)',
          position: 'fixed',
          top: '43px',
          right: '107px',
        })
      ),
      transition('start => end', [
        animate('2s cubic-bezier(.02,-1.38,.31,1.45)'),
      ]),
    ]),
  ],
})
export class ProductShopComponent implements OnInit, OnDestroy, OnChanges {
  showPanel = false;
  user!: ProfileDataRequest;
  currentUser!: UserInfo;
  isAdmin!: Boolean;
  clientUser!: User;
  favoriteRequest!: FavoriteRequest;
  buttonDisable = false;
  @Input() counter!: number;
  @Input() product!: ShopItemModel;
  @Input() inFavorite!: boolean;
  @Output() quantity = new EventEmitter<number>();
  @Output() favoriteChange = new EventEmitter<ShopItemModel>();
  @Output() isModalOpen = new EventEmitter<boolean>();
  @Output() increaseCart = new EventEmitter<ShopItemModel>();
  @Output() decreaseCart = new EventEmitter<ShopItemModel>();
  isCartButtonActive: boolean = true;
  unsubscribe: Subscription = new Subscription();
  shopCart: CartProductModel[] = [];
  isProcessing = false;
  showCampaignProductDiv: boolean = false;
  productData!: ShopItemModel;
  isReadingPoints: boolean = true;
  private subscription!: Subscription;
  firstCall: boolean = false;
  pointsSubscription!: Subscription;
  points!: number;
  noAnimation: boolean = false;

  constructor(
    public authService: AuthService,
    private shopService: ShopService,
    private perfilService: PerfilService,
    private checkoutService: CheckoutService,
    public dialog: MatDialog,
    private icons: CustomIconsService,
    private snackBarService: SnackBarService,
    private cartService: CartService,
    private cdr: ChangeDetectorRef,
    public pointsService: PointsService
  ) { }

  ngOnChanges(data: any): void { }

  ngOnInit(): void {
    this.user = this.perfilService.getCurrentUserInfo();
    if (this.user.role == 'Administrador') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.unsubscribe.add(
      this.cartService.shopCart$.subscribe((shopCart) => {
        this.shopCart = shopCart;
        let cartProduct: CartProductModel | undefined;

        if (this.isCartButtonActive === false) {
          cartProduct = this.shopCart.find(
            (sc) =>
              sc.productId === this.product.reference && sc.usePoints === true
          );
        } else {
          cartProduct = this.shopCart.find(
            (sc) =>
              sc.productId === this.product.reference && sc.usePoints === false
          );
        }
        this.product.productQuantity = cartProduct ? cartProduct.quantity : 0;
      })
    );
    this.subscription = this.pointsService.isReadingPoints$.subscribe(
      value => {
        this.isReadingPoints = value;
      }
    );
    this.pointsSubscription = this.pointsService.readPoints$.subscribe(
      points => {
        this.points = points;
      }
    );
  }

  onKeyDown(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    if (+currentValue >= 1000 && !this.isControlKey(event)) {
      event.preventDefault();
    }
  }

  isControlKey(event: KeyboardEvent): boolean {
    const controlKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'];
    return controlKeys.includes(event.key);
  }


  toggleCampaignProductDiv(): void {
    this.showCampaignProductDiv = !this.showCampaignProductDiv;
  }

  toggleButton() {
    this.isCartButtonActive = !this.isCartButtonActive;
    if (this.isCartButtonActive === false) {
      const cartProduct = this.shopCart.find(
        (sc) => sc.productId === this.product.reference && sc.usePoints === true
      )!;
      this.product.productQuantity = cartProduct ? cartProduct.quantity : 0;
    } else {
      const cartProduct = this.shopCart.find(
        (sc) =>
          sc.productId === this.product.reference && sc.usePoints === false
      )!;
      this.product.productQuantity = cartProduct ? cartProduct.quantity : 0;
    }
  }

  handleFavoriteClick(): void {
    this.product.favorite = !this.product.favorite;

    this.favoriteRequest = {
      Username: this.user.username,
      Reference: this.product.reference,
    };

    if (this.product.favorite === true) {
      this.unsubscribe.add(
        this.shopService
          .createFavorite(this.favoriteRequest)
          .subscribe()
      );
      this.snackBarService.openSnackBar(
        'Produto adicionado à lista de Favoritos',
        '#0AA174',
        '../../assets/Icons/correct.png'
      );
    } else {
      this.unsubscribe.add(
        this.shopService.deleteFavorite(this.favoriteRequest).subscribe((x) => {
          this.favoriteChange.emit(this.product);
        })
      );
      this.snackBarService.openSnackBar(
        'Produto removido da lista de Favoritos!',
        '#0AA174',
        '../../assets/Icons/correct.png'
      );
    }
  }

  handleRemoveFavoriteClick(): void {
    const selectedProduct = this.product;
    const dialogRef = this.dialog.open(FavoriteModalComponent, {
      data: selectedProduct,
    });
    this.unsubscribe.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result == true) {
          this.handleFavoriteClick();
        }
      })
    );
  }

  animationState = 'start';
  private productQueue: ShopItemModel[] = [];
  private animationTimeout: any;

  addQuantityClick(shopItem: ShopItemModel): void {
    this.productQueue.push(shopItem);

    if (!this.isProcessing) {
      this.startProcessing();
    }
    if (!this.noAnimation) {
      clearTimeout(this.animationTimeout);
      this.animationTimeout = setTimeout(() => {
        this.triggerAnimation();
        this.finalizeUpdate(true);
      }, 500);
    }
    this.noAnimation = false

  }

  private startProcessing(): void {
    if (this.productQueue.length === 0) {
      this.isProcessing = false;
      return;
    }

    const shopItem = this.productQueue[this.productQueue.length - 1];
    if (
      !this.isCartButtonActive &&
      this.points - shopItem!.points < 0
    ) {
      this.noAnimation = true;
      this.snackBarService.openSnackBar(
        'Quantidade de pontos insuficiente',
        '#F97066',
        '../../../../assets/Icons/ErrorIcon.png'
      );
    } else {
      this.unsubscribe.add(
        this.cartService
          .increaseCartMethod(shopItem!, !this.isCartButtonActive, false)
          .subscribe()
      );
    }
  }

  private finalizeUpdate(isAdding: boolean): void {
    this.isProcessing = true;
    const lastProduct = this.productQueue[this.productQueue.length - 1];
    if (lastProduct) {
      if (isAdding) {
        this.unsubscribe.add(
          this.cartService.increaseCartMethod(lastProduct, !this.isCartButtonActive, true).subscribe({
            next: (resp) => {
              if (resp) {
                this.snackBarService.openSnackBar(
                  'Produto adicionado ao carrinho!',
                  '#0AA174',
                  '../../assets/Icons/correct.png'
                );
              }
              this.isProcessing = false;
            }
          })
        );
      }
      else {
        this.unsubscribe.add(
          this.cartService
            .decreaseCartMethod(lastProduct, !this.isCartButtonActive, true)
            .subscribe((operation) => {
              setTimeout(() => {
                if (operation) {
                  this.snackBarService.openSnackBar(
                    'Produto removido do carrinho!',
                    '#0AA174',
                    '../../assets/Icons/correct.png'
                  );
                }
                this.isProcessing = false;
              }, 250);
            })
        );
      }
    }
  }

  private triggerAnimation(): void {
    this.animationState = 'end';
    setTimeout(() => {
      this.animationState = 'start';
      this.isProcessing = false;
    }, 1000);
  }

  startProcessingRemove(): void {
    const shopItem = this.productQueue[this.productQueue.length - 1];
    if (shopItem && shopItem.productQuantity > 0) {
      this.unsubscribe.add(
        this.cartService
          .decreaseCartMethod(shopItem, !this.isCartButtonActive, false)
          .subscribe((operation) => {
            setTimeout(() => {
              if (operation) {
                this.snackBarService.openSnackBar(
                  'Produto removido do carrinho!',
                  '#0AA174',
                  '../../assets/Icons/correct.png'
                );
              }
              this.isProcessing = false;
            }, 250);
          })
      );
    }
  }

  removeQuantityClick(shopItem: ShopItemModel): void {
    this.productQueue.push(shopItem);

    if (!this.isProcessing) {
      this.startProcessingRemove();
    }

    clearTimeout(this.animationTimeout);
    this.animationTimeout = setTimeout(() => {
      this.triggerAnimation();
      this.finalizeUpdate(false);
    }, 500);
  }

  changeQuantity(
    newValue: string,
    shopItem: ShopItemModel,
    oldValue: number
  ): void {
    let numericValue = parseInt(newValue);

    if (numericValue === oldValue || isNaN(numericValue) || numericValue < 0) {
      this.product.productQuantity = NaN;
      this.cdr.detectChanges();
      this.product.productQuantity = oldValue;
      return;
    }

    if (
      this.isCartButtonActive === false &&
      this.points +
      oldValue * shopItem.points -
      numericValue * shopItem.points <
      0
    ) {
      this.product.productQuantity = NaN;
      this.cdr.detectChanges();
      this.product.productQuantity = oldValue;
      this.snackBarService.openSnackBar(
        'Quantidade de pontos insuficiente',
        '#F97066',
        '../../../../assets/Icons/ErrorIcon.png'
      );
      return;
    }

    this.unsubscribe.add(
      this.cartService
        .changeQuantityCartMethod(
          shopItem,
          !this.isCartButtonActive,
          numericValue
        )
        .subscribe((operation) => {
          if (operation) {
            if (oldValue)
              if (numericValue !== 0 && oldValue !== 0) {
                this.snackBarService.openSnackBar(
                  'Quantidade do produto alterada no carrinho!',
                  '#0AA174',
                  '../../assets/Icons/correct.png'
                );
              }
            if (numericValue === 0) {
              this.snackBarService.openSnackBar(
                'Produto removido com sucesso!',
                '#0AA174',
                '../../assets/Icons/correct.png'
              );
            }
            if (oldValue === 0 && numericValue > 0) {
              this.snackBarService.openSnackBar(
                'Produto adicionado com sucesso!',
                '#0AA174',
                '../../assets/Icons/correct.png'
              );
            }
          }
        })
    );
  }

  openProductDetails(): void {
    this.unsubscribe.add(
      this.shopService.getCardDetails(this.product.reference, this.user.clientId,).subscribe((response) => {
        this.product.atc = response.atc;
        this.product.lab = response.lab;
        this.product.family = response.family;
        this.product.group = response.group;
        this.product.species = response.species;
        this.product.validate = response.validate;
        this.product.files = response.files;
      })
    );

    this.isModalOpen.emit(true);
    const dialogRef = this.dialog.open(ProductModalComponent, {
      data: this.product,
    });
    this.unsubscribe.add(
      dialogRef.afterClosed().subscribe(() => this.isModalOpen.emit(false))
    );
  }

  openProductBonus(): void {
    this.isModalOpen.emit(true);
    
    var dataToSend: DialogData = {
      bonusData: this.product!,
      addButton: true
    };
    const dialogRef = this.dialog.open(ProductBonusModalComponent, {
        data: dataToSend
    });
    
    this.unsubscribe.add(
      dialogRef.afterClosed().subscribe(() => this.isModalOpen.emit(false))
    );
  }


  showProductImage() {
    return environment.shop_images + this.product.productImage;
  }

  OnkeyDown(e: any) {
    if (!/ArrowLeft|ArrowRight|Backspace|Enter/.test(e.key) && isNaN(e.key)) {
      e.preventDefault();
    }
  }

  onEnterPress(inputElement: HTMLInputElement): void {
    inputElement.blur();
  }

  ngOnDestroy(): void {
    this.unsubscribe.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.pointsSubscription) {
      this.pointsSubscription.unsubscribe();
    }
  }

  getIva() {
    switch (this.product.iva) {
      case "0":
        return {
          label: "0",
          backgroundColor: "background-color: #7BA9511A",
          color: "color: #7BA951"
        }
      case "6":
        return {
          label: "6",
          backgroundColor: "background-color: #7BA9511A",
          color: "color: #7BA951"
        }
      case "13":
        return {
          label: "13",
          backgroundColor: "background-color: #FDB3221A",
          color: "color: #FDB322"
        }
      case "23":
        return {
          label: "23",
          backgroundColor: "background-color: #F970661A",
          color: "color: #F97066"
        }
      default:
        return {}
    }

  }

  getBackgroundColor() {
    const currentUser: string = this.user?.username;
    if (
      this.product.color &&
      currentUser.toUpperCase().substring(0, 1) == 'G'
    ) {
      switch (this.product.color) {
        case 1:
          return {
            borderStyle: 'border: 1px solid #7BA951',
            backgroundColor: 'background-color: #7BA951',
            label: 'Primeira eleição',
            icon: '../../../assets/Icons/1opçao.png',
          };
        case 2:
          return {
            borderStyle: 'border: 1px solid #FDB022',
            backgroundColor: 'background-color: #FDB022',
            label: 'Segunda eleição',
            icon: '../../../assets/Icons/2opçao.png',
          };
        case 3:
          return {
            borderStyle: 'border: 1px solid #F97066',
            backgroundColor: 'background-color: #F97066',
            label: 'Último recurso',
            icon: '../../../assets/Icons/3opçao.png',
          };
        default:
          return {
            borderStyle: 'border: 1px solid #00aeae',
            backgroundColor: 'background-color: #FFF',
            label: '',
            icon: '../../../assets/Icons/produtoEncomenda.png',
          };
      }
    }
    if (this.product.request) {
      return {
        borderStyle: 'border: 1px solid #F97066',
        backgroundColor: 'background-color: #F97066',
        label: 'Produto por encomenda',
        icon: '../../../assets/Icons/ProdutoEncomenda.png',
      };
    } else {
      if (this.product.bonus.length > 0) {
        return {
          borderStyle: 'border: 1px solid #00aeae',
          backgroundColor: 'background-color: #FFF',
          label: '',
        };
      } else {
        return { label: '' };
      }
    }
  }
}
