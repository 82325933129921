import { PerfilService } from './../api/services/perfil.service';
import { EncryptionService } from './../api/services/encryption.service';
import { Router } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserDto } from 'src/app/api/models';
import { AuthService } from 'src/app/api/services/auth.service';
import { SnackBarService } from '../api/services/snack-bar.service';
import { CookieService } from 'ngx-cookie-service';
import { ComunicadosService } from '../api/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageKeys } from '../api/models/storageKeys';
import { ProfileDataRequest } from '../api/models/perfil';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnDestroy {
  loginForm: FormGroup;
  subscription: Subscription[] = [];
  showPassword: boolean = false;
  isLoading: boolean = false;


  constructor(
    private authService: AuthService,
    private router: Router,
    private snackBarService: SnackBarService,
    private cookieService: CookieService,
    private comunicadoServ: ComunicadosService,
    private formBuilder: FormBuilder,
    private encryptionService: EncryptionService,
    private encrypt: EncryptionService,
    private PerfilService: PerfilService,
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const bio2Token = localStorage.getItem('BIO2_AccessToken');
    //this.authService.clearSessionStorage()
    if (bio2Token) {
      const decodedToken = this.authService.getDecodedAccessToken(bio2Token);
      const role = decodedToken['role'];
      if (role == 'Administrador') {
        this.router.navigate(['/login/selection']);
      }
      if (role == 'Laboratorio') {
        this.router.navigateByUrl('/home');
        //this.router.navigate(['login/selection/subLogin']);
      } else {
        this.router.navigateByUrl('/home');
      }
    }
  }

  async login(): Promise<void> {
    const user: UserDto = {
      username: this.encryptionService.encrypt(this.loginForm.value.username),
      password: this.encryptionService.encrypt(this.loginForm.value.password),
    };
    this.isLoading = true;
    this.subscription.push(
      this.authService.login(user).subscribe(
        async (resp) => {
          localStorage.setItem('BIO2_AccessToken', resp.biO2_AccessToken);
          localStorage.setItem('BIO2_RefreshToken', resp.biO2_RefreshToken);
          this.authService.decodeToken(resp.biO2_AccessToken);
          const decodedToken = this.authService.getDecodedAccessToken(
            resp.biO2_AccessToken
          );
          const role = decodedToken['role'];

          this.encrypt.setEncryptedItem(
            StorageKeys.BIO2_CLIENT_ADDRESS_ID,
            decodedToken['morada']
          );

          let activeComunicado = await this.comunicadoServ.getComunicadoByUser(
            decodedToken['unique_name']
          );
          this.comunicadoServ.setActiveComunicado(activeComunicado);
          this.isLoading = false;
          this.PerfilService.getProfileData();
          if (!this.cookieService.check('cookiesAcceptedTC')) {
            this.router.navigate(['/condicoes']);
          } else if (decodedToken['avatar'] == 'NULL' || decodedToken['avatar'] == 'null' || decodedToken['avatar'] == '') {
            this.router.navigate(['/avatar']);
          } else if (activeComunicado.length > 0 && role === 'Administrador') {
            this.router.navigate(['/comunicado']);
          } else if (activeComunicado.length > 0 && role !== 'Administrador') {
            this.router.navigate(['/comunicado']);
          } else if (role === 'Administrador') {
            this.router.navigate(['/login/selection']);
          } else if (role === 'Laboratorio') {
            this.router.navigate(['login/selection/subLogin']);
          } else if (role == 'PetVet') {
            this.router.navigate(['/home']);
          } else {
            this.router.navigate(['/home']);
          }
          this.snackBarService.openSnackBar(
            'Login Efetuado com sucesso',
            '#0AA174',
            '../../assets/Icons/correct.png'
          );
        },
        (error) => {
          // Could not connect to backend/is down
          if (error.status === 0) {
            this.isLoading = false;
            this.snackBarService.openSnackBar(
              'Estamos em atualização, prometemos ser breves. Lamentamos qualquer inconveniente',
              '#F97066',
              '../../assets/Icons/ErrorIcon.png'
            );
          // Error processing the login
          } else {
            this.isLoading = false;
            this.snackBarService.openSnackBar(
              'Erro ao efetuar o login.',
              '#F97066',
              '../../assets/Icons/ErrorIcon.png'
            );
          }
        }
      )
    );
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  ngOnDestroy(): void {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
}
